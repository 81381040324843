import React from "react"
import Layout from "../../components/h/layout"

const HalloweenIPage = () => (
  <Layout>
    <h2>Sasquatch got this for your dad to use.</h2>
    <h2>How do you want your burger cooked? You choose.</h2>
  </Layout>
)

export default HalloweenIPage
